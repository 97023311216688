import "@gamesheet/ui/build/index.css"
import TagManager from 'react-gtm-module';
import Smartlook from "smartlook-client"

import { useAppState } from './state/app/useAppState';
import { LoginLayout } from "./layouts/LoginLayout";
import { LoadingLayout } from "./layouts/LoadingLayout";
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { useWindowState } from './state/app/useWindowState';
import { useSetUserState, useUserState } from './state/user/useUserState';
import { Suspense, useEffect } from 'react';
import { useTeamsCountOnLogin } from "./state/data/useTeams";
import { useUnleashContext } from "@unleash/proxy-client-react";

function App() {

  useSetUserState()
  useWindowState()
  useTeamsCountOnLogin()
  const updateContext = useUnleashContext();

  const app = useAppState()

  useEffect(() => { 
    let mounted = true
    if (app.isLoggedin) {
      const updateUnleashContext = async () => {
        try {
          // Await the asynchronous operation
          if (mounted) {
            await updateContext({ userId: app.unleashContext.userId, properties: { sportId: app.unleashContext.sportId } });
          }
          
        } catch (error) {
          // Handle any errors that occurred during the update
          console.error("Failed to update Unleash context:", error);
        }
      };
      updateUnleashContext()
    }
    return () => {
      mounted = false
    }
  }, [app.unleashContext, app.isLoggedin]);
  

  const isFirebaseuthComplete = app.loading.isComplete('firebaseAuth')
  const isRevalidatingUserComplete = app.loading.isComplete('revalidatingUser')
  const showLoadingLayout = !isFirebaseuthComplete || (!isFirebaseuthComplete && !isRevalidatingUserComplete)
  const showLoginLayout = !app.isLoggedin
  const shouldSetPluginData = app.isLoggedin && app.activeUser?.uid && app.activeUser?.email

  useEffect(() => {
    
    if (!shouldSetPluginData) {
      return
    }

    const user = app.activeUser!
    TagManager.dataLayer({ dataLayer: { userId: user.uid, userRole: "manager" }})
    Smartlook.identify(user.uid, { email: user.email!, userRole: "manager" })

  }, [shouldSetPluginData])




  if (showLoadingLayout) {
    return <LoadingLayout />
  }

  if (showLoginLayout) {
    return <LoginLayout />
  }

  return <Outlet />;

}

export default App;

// 